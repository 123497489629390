<template>
  <!--Model宽度：850px-->
  <a-spin :spinning="pageLoading">
    <div>
      <a-spin :spinning="metricsHistoryLoading">
        <a-tabs defaultActiveKey="chart" :tabBarStyle="{borderBottom:'0'}">
          <div slot="tabBarExtraContent">
            <a-radio-group default-value="day" @change="handleChangeMetricsHistoryDate">
              <a-radio-button value="day">
                当日
              </a-radio-button>
              <a-radio-button value="triduum">
                近三天
              </a-radio-button>
              <a-radio-button value="week">
                近七天
              </a-radio-button>
            </a-radio-group>
            <a-range-picker
              :disabledDate="disabledDate"
              :value="metricsHistoryTime"
              :showTime="{ format: 'HH:mm' }"
              :allowClear="false"
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM-DD HH:mm"
              @ok="handleModalPickerOk"
              @openChange="onOpenChange"
              @calendarChange="onCalendarChange"
              @change="(value) => (metricsHistoryTime = value)"
            />
          </div>
          <a-tab-pane tab="图表" key="chart">
            <Line02
              v-if="chartMetricsHistoryData"
              :data="chartMetricsHistoryData"
              :enNameAlias="enNameAlias"
              yText="指标值"
              xName="collectTime"
              yName="collectValue"
              :height="400"
              :yUnit="unit"
              :isRunt="isRunt"
            ></Line02>
            <a-empty style="margin-top: 50px" v-else/>
          </a-tab-pane>
          <a-tab-pane tab="数据" key="data">
            <a-table
              :columns="metricsHistoryTableColumns"
              :dataSource="metricsHistoryData"
              :rowKey="(record, index) => index"
              :pagination="pagination"
              @change="tableChange"
              :scroll="{ y: 396 }"
              size="small"
              bordered
            >
              <template slot="_index" slot-scope="text, record, index">
                {{ index + 1 }}
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
  </a-spin>
</template>

<script>
  import moment from 'moment'
  import Line02 from '@/components/fs-chart/Line02'
  import SERVICE_URLS from '@/api/service.url'
  import { mapState } from 'vuex'

  export default {
    name: 'Detail',
    components: { Line02 },
    props: {
      monitoringPointId: {
        default: ''
      },
      unit: {
        type: String,
        default: ''
      },
      isRunt: {
        type: Boolean,
        default: false
      },
      enNameAlias: {
        type: String,
        default: ''
      },
      tag: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight
        }
      })
    },
    data () {
      return {
        // ↓页面的加载
        pageLoading: false,
        realId: null,
        // ↓Modal中历史数据的加载状态
        metricsHistoryLoading: false,
        // 历史数据的时间值
        metricsHistoryTime: [moment().startOf('day'), moment().endOf('day')],
        metricsHistoryStartTime: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        metricsHistoryEndTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        // 历史数据的表头
        metricsHistoryTableColumns: [
          {
            title: '序号',
            dataIndex: '_index',
            align: 'center',
            width: 80,
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '采集时间',
            width: 450,
            align: 'center',
            dataIndex: 'collectTime'
          },
          {
            title: '指标值' + (this.unit ? ' (' + this.unit + ')' : ''),
            align: 'center',
            customRender: (text, record) => {
              //es6写法
              if (this.isRunt) {
                const time = this.formatSecondTime(record.collectValue)
                let value = (time.D !== 0 ? time.D + '天' : '') + (time.H !== 0 ? time.H + '小时' : '') + (time.m !== 0 ? time.m + '分' : '') + (time.s + '秒')
                return value
              } else {
                return `${record.collectValue}`
              }
            }
          }
        ],
        // 历史数据的Data
        metricsHistoryData: [],
        chartMetricsHistoryData: [],
        searchMetricsHistoryDataParams: {
          page: 0,
          size: 10,
          pointId: '',  // 监测点id
          realId: '',  // 实时指标id
          startTime: '',
          endTime: '',
          no: '',
          metricsKey: ''
        },
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        disabledCurrent: undefined
      }
    },
    methods: {
      handleMetricsHistory () {
        this.restPage()
        if (!this.metricsHistoryLoading) {
          this.searchMetricsHistoryDataParams.realId = this.realId
          this.searchMetricsHistoryDataParams.pointId = this.monitoringPointId
          this.searchMetricsHistoryDataParams.startTime = this.metricsHistoryStartTime
          this.searchMetricsHistoryDataParams.endTime = this.metricsHistoryEndTime
          this.metricsHistoryLoading = true
          // this.getChartMetricsHistoryList()
          this.handleMetricsHistoryPage()
          this.metricsHistoryLoading = false
        }
      },
      handleMetricsHistoryPage () {
        this.metricsHistoryData = []
        var url = ''
        // 历史数据分页列表
        if (this.tag) {
          url = SERVICE_URLS.equipment2.monitoringPoint.metrics_history_page_by_no
        } else {
          url = SERVICE_URLS.equipment2.monitoringPoint.metrics_history_page
        }
        this.$http(this, {
          url: url,
          noTips: true,
          data: this.searchMetricsHistoryDataParams,
          success: (data) => {
            if (data.body.content) {
              this.chartMetricsHistoryData = data.body.content
              this.metricsHistoryData = data.body.content
              this.pagination.pageSize = data.body.size
              this.pagination.total = data.body.totalElements
              this.pagination.current = data.body.number + 1
              this.searchMetricsHistoryDataParams.size = data.body.size
              this.searchMetricsHistoryDataParams.page = data.body.number
            }
          },
          error: () => {
          }
        })
      },
      // getChartMetricsHistoryList () {
      //   // 历史图表数据
      //   this.$http(this, {
      //     url: SERVICE_URLS.equipment2.monitoringPoint.metrics_history_list,
      //     noTips: true,
      //     data: this.searchMetricsHistoryDataParams,
      //     success: (data) => {
      //       this.chartMetricsHistoryData = data.body.body.content
      //     },
      //     error: () => {
      //     }
      //   })
      // },
      onOpenChange (status) {
        this.disabledCurrent = null
      },
      onCalendarChange (dates) {
        this.disabledCurrent = dates[0]
      },
      disabledDate (current) {
        if (!this.disabledCurrent) return false
        return (current && current < moment(this.disabledCurrent).subtract(7, 'd').startOf('day')) || current > moment(this.disabledCurrent).add(7, 'd').endOf('day')
      },
      handleChangeMetricsHistoryDate (type) {
        if (type.target.value === 'triduum') {
          this.metricsHistoryTime = [moment().subtract('days', 2).startOf('day'), moment().subtract('days', 0).endOf('day')]
        } else if (type.target.value === 'week') {
          this.metricsHistoryTime = [moment().subtract('days', 6).startOf('day'), moment().subtract('days', 0).endOf('day')]
        } else {
          this.metricsHistoryTime = [moment().startOf(type.target.value), moment().endOf(type.target.value)]
        }
        this.metricsHistoryStartTime = this.metricsHistoryTime[0].format('YYYY-MM-DD HH:mm:ss')
        this.metricsHistoryEndTime = this.metricsHistoryTime[1].format('YYYY-MM-DD HH:mm:ss')
        this.handleMetricsHistory()
      },
      handleModalPickerOk (value) {
        this.metricsHistoryStartTime = value[0].format('YYYY-MM-DD HH:mm:ss')
        this.metricsHistoryEndTime = value[1].format('YYYY-MM-DD HH:mm:ss')
        this.handleMetricsHistory()
      },
      tableChange (pagination, filters, sorter) {
        this.searchMetricsHistoryDataParams.page = pagination.current - 1
        this.searchMetricsHistoryDataParams.size = pagination.pageSize
        this.handleMetricsHistoryByPage()
      },
      handleMetricsHistoryByPage () {
        if (!this.metricsHistoryLoading) {
          this.metricsHistoryLoading = true
          this.handleMetricsHistoryPage()
          this.metricsHistoryLoading = false
        }
      },
      restPage () {
        this.searchMetricsHistoryDataParams.page = 0
      }
    },
    filters: {
      // roundNumber (value) {
      //   if (typeof value === 'number') {
      //     let result = null
      //     // ↓四舍五入的值
      //     result = Math.round(value * 100) / 100
      //     // 判断四舍五入的值是不是小数
      //     if (result - parseInt(result) > 0) {
      //       // 是小数,补零算法
      //       let s_x = result.toString()
      //       let pos_decimal = s_x.indexOf('.')
      //       if (pos_decimal < 0) {
      //         pos_decimal = s_x.length
      //         s_x += '.'
      //       }
      //       while (s_x.length <= pos_decimal + 2) {
      //         s_x += '0'
      //       }
      //       return s_x
      //     } else {
      //       // 是整数，直接返回
      //       return result
      //     }
      //   } else {
      //     return value
      //   }
      // }
    }
  }
</script>

<style scoped>
  .modal-text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }
</style>
