<template>
  <a-date-picker :mode="mode" format="YYYY年"
                 :open="yearPickShow"
                 :allowClear="false"
                 :defaultValue="defaultValue"
                 :value="value"
                 :showToday="false"
                 placeholder="请选择年份"
                 @panelChange="handlePanelChange"
                 @openChange="handleOpenChange"
  />

</template>
<script>
  import moment from 'moment'

  export default {
    props: {
      defaultValue: {}
    },
    data () {
      return {
        mode: 'year',
        value: '',
        yearPickShow: false
      }
    },
    created () {
    },
    methods: {
      moment,
      handlePanelChange (value, mode) {
        this.value = value
        this.$emit('selectYear', value)
        this.yearPickShow = false
      },
      handleOpenChange (status) {
        this.yearPickShow = status
      }
    }
  }
</script>
<style lang="less">

</style>