<template>
  <div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="集污点名称">
              <a-input v-model="searchParams.name" placeholder="请填写集污点名称"/>
            </a-form-item>
            <a-form-item label="预警类型">
              <a-radio-group @change="warnTypeClick" v-model="warnType" button-style="solid">
                <a-radio-button value="ALL">全部</a-radio-button>
                <a-radio-button value="FAULT">故障</a-radio-button>
                <a-radio-button value="WARN">预警</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item>
              <area-selection @onclickArea="onclickArea" @search="search"></area-selection>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table size="middle"
               :columns="columns"
               :dataSource="tableData"
               :loading="tableLoading"
               :pagination="pagination"
               @change="tableChange"
               :rowKey="(record) => record.id">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="online" slot-scope="text, record">
          <a-tag color="green" v-if="record.online">在线</a-tag>
          <a-tag v-else>离线</a-tag>
        </template>
        <template slot="warn" slot-scope="text, record">
          <a-tag color="yellow" v-if="record.warn">是</a-tag>
          <a-tag v-else>否</a-tag>
        </template>
        <template slot="fault" slot-scope="text, record">
          <a-tag color="red" v-if="record.fault">是</a-tag>
          <a-tag v-else>否</a-tag>
        </template>
        <template slot="waterHeight" slot-scope="text, record">
          <a @click="onClickMetrics(record,'WATER_HEIGHT')">{{ record.waterHeight }}</a>
        </template>
        <template slot="emptyHeight" slot-scope="text, record">
          <a @click="onClickMetrics(record,'EMPTY_HEIGHT')">{{ record.emptyHeight }}</a>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="handleView(record.spot)">报抽历史</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleReport(record.spot)">登记</a>
        </template>
      </a-table>
    </a-card>
    <a-drawer
      title="集污点详细信息"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="1200px"
      :visible="detailDrawer"
      :mask="false">
      <spot-detail ref="viewRef"></spot-detail>
    </a-drawer>
    <a-modal
      title="报抽登记"
      :visible="addModal"
      width="1200px"
      :maskClosable="false"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal">
      <register-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></register-form>
    </a-modal>
    <a-modal
      :title="metricsTitle+'-历史数据'"
      :visible="metricsVisible"
      @cancel="handleCancel"
      :footer="null"
      :width="1200"
      :destroyOnClose="true"
    >
      <MetricsHistory ref="metricsRef" :unit=metricsUnit :tag="true"></MetricsHistory>
    </a-modal>
  </div>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import { monitorColumns } from './common/common'
import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
import moment from 'moment'
import SpotDetail from '../gatherspot/Detail'
import RegisterForm from '../reportrecord/report/Form'
import AreaSelection from '../statistics/loophistory/AreaSelection'
import MetricsHistory from '../dispatchpanel/cover/MetricsHistory'

export default {
  name: 'LoopForecastList',
  components: { SpotDetail, RegisterForm, AreaSelection, MetricsHistory },
  mixins: [entityCRUDWithCopy],
  filters: {
    calDays (time) {
      return moment(time).diff(moment(), 'days')
    }
  },
  data () {
    return {
      dateSearch: null,
      entityBaseUrl: SERVICE_URLS.csgz.gatherSpotBindApi,
      searchParams: {
        page: 0,
        size: 10,
        direction: '',
        order: '',
        name: '', //集污点名称
        warn: '',
        fault: '',
        online: '',
        codes: ''
      },
      initColumns: monitorColumns(),
      warnType: 'ALL',
      detailDrawer: false,
      addModal: false,
      metricsVisible: false,
      metricsUnit: '',
      metricsTitle: ''
    }
  },
  methods: {
    warnTypeClick () {
      if (this.warnType === 'ALL') {
        this.searchParams.warn = ''
        this.searchParams.fault = ''
      } else if (this.warnType === 'WARN') {
        this.searchParams.warn = true
        this.searchParams.fault = ''
      } else {
        this.searchParams.warn = ''
        this.searchParams.fault = true
      }
      this.search()
    },
    detailClose () {
      this.detailDrawer = false
      //重置报抽查询条件按照编号查询
      this.$refs.viewRef.resetData()
    },
    handleView (record) {
      this.detailDrawer = true
      this.$nextTick(() => {
        //重置报抽查询条件按照编号查询
        this.$refs.viewRef.resetData()
        this.$refs.viewRef.loadData(record.id)
      })
    },
    handleReport (record) {
      this.addModal = true
      this.$nextTick(() => {
        this.$refs.addRef.findGatherSpot(record)
      })
    },
    //上报
    handleAddOk () {
      this.$refs['addRef'].submitAdd()
    },
    closeAddModal () {
      this.addModal = false
    },
    addSuccess () {
      this.addModal = false
      this.$message.success('登记成功！')
      this.search()
    },
    addError () {
      this.$message.warning('登记失败！')
      this.addModal = false
    },
    resetForm () {
      this.warnType = 'ALL'
      this.searchParams.codes = ''
      this.searchParams.name = ''
      this.searchParams.page = 0
      this.searchParams.size = 10
      this.searchParams.warn = ''
      this.searchParams.fault = ''
      this.search()
    },
    onclickArea (areaCodes) {
      this.searchParams.codes = areaCodes.toString()
    },
    onClickMetrics (item, metricsName) {
      if (item) {
        this.metricsUnit = 'm'
        this.metricsTitle = item.name
        this.metricsVisible = true
        this.$nextTick(() => {
          this.$refs.metricsRef.searchMetricsHistoryDataParams.no = item.no
          this.$refs.metricsRef.searchMetricsHistoryDataParams.metricsKey = metricsName
          this.$refs.metricsRef.handleMetricsHistory()
        })
      }
    },
    handleCancel () {
      this.metricsVisible = false
    }
  }
}
</script>
