<template>
  <a-spin :spinning="spinShow">
    <div style="float: right">
      <a-button icon="printer" @click="handlePrint" type="primary">打印</a-button>
    </div>
    <a-descriptions :column="3" style="margin-left: 10px;font-size: 20px" title="基础信息">
      <a-descriptions-item label="集污点编号">{{ formItem.no }}</a-descriptions-item>
      <a-descriptions-item label="集污点名称">{{ formItem.name }}</a-descriptions-item>
      <a-descriptions-item label="集污点类型">
        <a-tag :color="formItem.type===spotTypeEnum.SANGE.value? 'blue' :formItem.type===spotTypeEnum.JIWUCHI.value?'red' : 'green'">
          {{ getSpotType(formItem) }}
        </a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="化粪池容积">{{ formItem.bulk }} m³</a-descriptions-item>
      <a-descriptions-item label="负责人姓名">{{ formItem.userName }}</a-descriptions-item>
      <a-descriptions-item label="负责人电话">{{ formItem.userPhone }}</a-descriptions-item>
      <a-descriptions-item label="负责人身份证号">{{ formItem.idcard ? formItem.idcard : '暂无身份证号' }}</a-descriptions-item>
      <a-descriptions-item label="院内院外">{{ formItem.positionType === 'nei' ? '院内' : '院外' }}</a-descriptions-item>
      <a-descriptions-item label="门牌号">{{ formItem.houseNo ? formItem.houseNo : '暂无门牌号' }}</a-descriptions-item>
      <a-descriptions-item label="采集类型">
        <a-icon style="color:#2ba245;font-size: 18px;margin-right: 6px" v-if="formItem.collectType === 'WECHAT' "
                type="wechat"
                theme="filled"></a-icon>
        <a-icon style="color:#2b97ff;font-size: 18px;margin-right: 6px"
                v-if="formItem.collectType === 'REGISTER' "
                type="phone"
                theme="filled"></a-icon>
        <a-icon style="color:#5007ff;font-size: 18px;margin-right: 6px"
                v-if="formItem.collectType === 'IMPORT' "
                type="import"></a-icon>
        {{
          formItem.collectType === 'WECHAT' ? '微信采集' : formItem.collectType ===
          'REGISTER' ? '登记采集' : formItem.collectType === 'IMPORT' ? '导入采集' : '其他途径'
        }}
      </a-descriptions-item>
      <a-descriptions-item label="经度">{{ formItem.latitude }}</a-descriptions-item>
      <a-descriptions-item label="纬度">{{ formItem.longitude }}</a-descriptions-item>
      <a-descriptions-item label="详细位置">{{ formItem.location }}</a-descriptions-item>
      <a-descriptions-item label="上次清运时间">{{ formItem.lastHandleTime }}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions :column="3" style="margin-left: 10px">
      <a-descriptions-item label="备注信息">{{ formItem.matter }}</a-descriptions-item>
    </a-descriptions>
    <div style="margin-top: 30px">
      <a-row style="margin-bottom: 12px">
        <a-col :span="12">
          <div style="font-size: 16px;margin-left: 10px;font-weight: bold;color: rgba(0, 0, 0, 0.85);">报抽记录</div>
        </a-col>
        <a-col :span="12">
          <div style="float: right">
            <a-radio-group
              v-model="searchContent" default-value="searchNo" @change="searchContentChange">
              <a-radio value="searchNo">
                编号检索
              </a-radio>
              <a-radio value="searchPhone">
                手机号检索
              </a-radio>
              <a-radio value="searchId">
                身份证检索
              </a-radio>
            </a-radio-group>
          </div>
        </a-col>
      </a-row>
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :scroll="{ x: 1000 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="name" slot-scope="text, record">
          <a-icon v-if="record.openId" style="color:#2ba245" type="wechat" theme="filled"/>&nbsp;<a-icon
          v-if="record.ifUrgent" style="color:#ff644e" type="fire" theme="filled"/>&nbsp;&nbsp;&nbsp;{{ record.spot ? record.spot.name : '' }}
        </template>
        <template slot="type" slot-scope="text, record">
          <div v-if="record.type==='TEL'">
            电话
          </div>
          <div v-if="record.type==='WECHAT'">
            微信
          </div>
          <div v-if="record.type==='APP'">
            APP
          </div>
          <div v-if="record.type==='OTHER'">
            自主任务
          </div>
        </template>
        <template slot="fromAddress" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              {{
                record.spot.houseNo !== null && record.spot.houseNo.length > 0 ? record.fromAddress + '[' +
                  record.spot.houseNo + ']' :
                  record.fromAddress
              }}
            </template>
            {{
              record.spot.houseNo !== null && record.spot.houseNo.length > 0 ? record.fromAddress + '[' +
                record.spot.houseNo + ']' :
                record.fromAddress
            }}
          </a-tooltip>
        </template>
        <template slot="handleStatus" slot-scope="text, record">
          <a-tag v-if="record.handleStatus==='SCHEDULED'" color="#33CC33">
            已调度
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSING'" color="#6666CC">
            处理中
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSED'" color="#CC6699">
            待回访
          </a-tag>
          <a-tag v-if="record.handleStatus==='FEEDBACK_COMPLETE'" color="#FF9900">
            待确认
          </a-tag>
          <a-tag v-if="record.handleStatus==='CONFIRM_COMPLETE'" color="#99CC33">
            已完成
          </a-tag>
        </template>
        <template slot="bindStatus" slot-scope="text, record">
          <a-tag v-if="record.bindStatus==='NOTOPEN'" color="red">
            未审核
          </a-tag>
          <a-tag v-if="record.bindStatus==='OPTIONAL'" color="blue">
            待接单
          </a-tag>
          <a-tag v-if="record.bindStatus==='CHECKED'" color="green">
            已接单
          </a-tag>
          <a-tag v-if="record.bindStatus==='DOING'" color="pink">
            清运中
          </a-tag>
          <a-tag v-if="record.bindStatus==='COMPLETE'" color="#CC9966">
            已清运
          </a-tag>
        </template>
      </a-table>
    </div>
    <a-modal
      title="选择年份"
      :visible="yearModal"
      :confirm-loading="confirmYearLoading"
      @ok="confirmYear"
      @cancel="cancelYear"
    >
      <YearPicker @selectYear="selectPrintYear" :defaultValue="printYear"></YearPicker>
    </a-modal>
    <a-modal title="打印预览" width="1100px"
             :visible="printModal"
             okText="确定"
             @ok="confirmPrint"
             @cancel="cancelPrint">
      <Print ref="printRef"></Print>
    </a-modal>
  </a-spin>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import { gatherSpotFormVO } from './common/common'
import { myReportColumns } from '../reportrecord/report/common/common'
import Print from './Print'
import YearPicker from './common/YearPicker'
import moment from 'moment'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'Detail',
  components: { Print, YearPicker },
  mixins: [],
  data () {
    return {
      formItem: gatherSpotFormVO(),
      spinShow: false,
      searchContent: 'searchNo',
      // entityBaseUrl: SERVICE_URLS.csgz.reportRecord,
      // searchUrl: SERVICE_URLS.csgz.reportRecord.search,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        fromPhone: '',
        idcard: '',
        no: ''
      },
      columns: myReportColumns(),
      tableData: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
      },
      printModal: false,
      confirmYearLoading: false,
      yearModal: false,
      printYear: moment().endOf('year'),
      spotTypeEnum: spotTypeEnum
    }
  },
  methods: {
    moment,
    loadData (id) {
      this.formItem = gatherSpotFormVO()
      this.$http(this, {
        url: SERVICE_URLS.csgz.gatherSpot.view,
        params: {
          id: id
        },
        noTips: 'true',
        success: (data) => {
          Object.assign(this.formItem, data.body)
          this.searchNo = this.formItem.no
          this.searchPhone = this.formItem.userPhone
          this.searchParams.no = this.formItem.no
          this.searchParams.fromPhone = ''
          this.searchParams.idcard = ''
          this.search()
        }
      })
    },
    //清空数据
    resetData () {
      this.searchContent = 'searchNo'
    },
    search () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.reportRecord.search,
        noTips: true,
        loading: 'tableLoading',
        data: this.searchParams,
        success: (data) => {
          this.tableData = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = data.body.number + 1
          this.pagination.pageSize = data.body.size
          this.searchParams.size = data.body.size
          this.searchParams.number = data.body.number
        }
      })
    },
    searchContentChange () {
      if (this.searchContent === 'searchPhone') {
        this.searchParams.fromPhone = this.formItem.userPhone
        this.searchParams.no = ''
        this.searchParams.idcard = ''
        this.search()
      } else if (this.searchContent === 'searchNo') {
        this.searchParams.no = this.formItem.no
        this.searchParams.fromPhone = ''
        this.searchParams.idcard = ''
        this.search()
      } else if (this.searchContent === 'searchId') {
        if (this.formItem.idcard === null || this.formItem.idcard === '') {
          this.$message.warning('该集污点没有录入身份证信息！')
          return
        }
        this.searchParams.idcard = this.formItem.idcard
        this.searchParams.fromPhone = ''
        this.searchParams.no = ''
        this.search()
      }
    },
    /*表格页数发生变化*/
    tableChange (pagination, filters, sorter) {
      this.searchParams.number = pagination.current - 1
      this.searchParams.size = pagination.pageSize
      this.search()
    },
    setSearchParamsPage (pagination) {
      this.searchParams.number = pagination.current - 1
      this.searchParams.size = pagination.pageSize
    },
    // 打印预览前选择年份
    handlePrint () {
      this.yearModal = true
    },
    selectPrintYear (val) {
      this.printYear = val
    },
    confirmYear () {
      this.confirmYearLoading = true
      this.$http(this, {
        url: SERVICE_URLS.csgz.reportRecord.print_report_record,
        noTips: true,
        params: {
          spotId: this.formItem.id,
          reportDate: this.printYear.format('YYYY')
        },
        success: (data) => {
          this.yearModal = false
          this.confirmYearLoading = false
          this.printModal = true
          this.$nextTick(() => {
            this.$refs.printRef.formItem = this.formItem
            this.$refs.printRef.tableData = data.body
            this.$refs.printRef.year = this.printYear.format('YYYY')
          })
        }
      })
    },
    cancelYear () {
      this.yearModal = false
    },
    confirmPrint () {
      this.$refs.printRef.onPrint()
    },
    cancelPrint () {
      this.printModal = false
      this.$refs.printRef.clearData()
    },
    getSpotType (item) {
      if (item.type === spotTypeEnum.JIWUCHI.value) {
        return '集污池'
      } else if (item.type === spotTypeEnum.SANGE.value) {
        return '三格化粪池'
      } else if (item.type === spotTypeEnum.DANWENG.value) {
        return '单翁'
      } else if (item.type === spotTypeEnum.LANI.value) {
        return '拉泥'
      } else if (item.type === spotTypeEnum.GONGCE.value) {
        return '公厕'
      } else {
        return '其他'
      }
    }
  }
}
</script>
