//清运预测信息表头
export const columns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '集污点名称',
      align: 'left',
      ellipsis: true,
      dataIndex: 'gatherSpot.name'
    },
    {
      title: '已清运次数',
      width: 140,
      align: 'center',
      dataIndex: 'numbers'
    },
    {
      title: '上次清运时间',
      width: 200,
      align: 'center',
      dataIndex: 'lastTime'
    },
    {
      title: '清运周期（天）',
      align: 'center',
      width: 140,
      dataIndex: 'cycle'
    },
    {
      title: '预测清运时间',
      align: 'center',
      width: 200,
      dataIndex: 'nextTime'
    },
    {
      title: '距离下次清运间隔',
      align: 'center',
      width: 200,
      scopedSlots: { customRender: 'days' }
    },
    {
      title: '操作',
      width: 130,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
// 监测
export const monitorColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '集污点名称',
      align: 'left',
      ellipsis: true,
      dataIndex: 'spot.name'
    },
    {
      title: '在线状态',
      align: 'center',
      scopedSlots: { customRender: 'online' }
    },
    {
      title: '预警',
      align: 'center',
      scopedSlots: { customRender: 'warn' }
    },
    {
      title: '故障',
      align: 'center',
      scopedSlots: { customRender: 'fault' }
    },
    {
      title: '液位(m)',
      align: 'center',
      scopedSlots: { customRender: 'waterHeight' }
    },
    {
      title: '空高(m)',
      align: 'center',
      scopedSlots: { customRender: 'emptyHeight' }
    },
    {
      title: '操作',
      width: 130,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
